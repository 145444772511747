import { LocationChangeEvent, PlatformLocation, ViewportScroller } from '@angular/common';
import { HttpUrlEncodingCodec } from '@angular/common/http';
import { Injectable, OnDestroy, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CmsService, ConfigurationService, WindowRef } from '@spartacus/core';
import { UndoBehaviorSubject } from '@util/classes/undo-behavior-subject.class';
import { Observable, Subject, Subscription, combineLatest, filter, map, of, switchMap, take, timer } from 'rxjs';

export enum UtilNavigationRule {
  /**
   * does not show any breadcrumbs - does not even reserve any space in the DOM
   */
  None = 'none',
  /**
   * delivers only one breadcrumb -> to the last page visited by the user
   */
  PageBack = 'pageBack',
  /**
   * delivers only one breadcrumb -> to the last url change -> this maybe the last page visited by the user
   */
  HistoryBack = 'historyBack',
  /**
   * delivers only one breadcrumb -> to the homepage
   */
  OnlyHomepage = 'onlyHomepage',
  /**
   * delivers only one breadcrumb -> to the Cart
   */
  ToCart = 'toCart',
    /**
   * delivers only one breadcrumb -> to the Cart
   */
  ToOrderHistory = 'toOrderHistory'
}

export interface UtilNavigationItem {
  type: 'pageChange' | 'queryChange';
  url?: string;
  pageUrl: string;
  pageId?: string;
  contextPieces?: {key: string; value: string;}[]
  queryParams?: Record<string, any>;
}

export interface UtilNavigationPageData {
  rule: UtilNavigationRule;
  targetItem: UtilNavigationItem;
}

@Injectable({
  providedIn: 'root'
})
export class UtilNavigationService implements OnDestroy {

  private readonly router = inject(Router);
  private readonly cmsService = inject(CmsService);
  private readonly configurationService = inject(ConfigurationService);
  private readonly windowRef = inject(WindowRef);
  private readonly viewportScroller = inject(ViewportScroller);
  private readonly location = inject(PlatformLocation);

  private routerEventsSub: Subscription;
  private urlContextPartsSub: Subscription;
  private onpopstateKindOfSubscription: VoidFunction;

  private pageId_navigationRuleMap = new Map<string, UtilNavigationRule>();

  private urlContextParts: string[];

  private history = new UndoBehaviorSubject<UtilNavigationItem>(null);
  private historyItemReplaceMode = false;

  private popstateEventsBehaviorSubject = new Subject<LocationChangeEvent>();

  get history$() {
    return this.history.asObservable();
  }

  /**
   * popstate event is emitted after the user pressed the back button of the browser or location.back()
   */
  get popstateEvents(): Observable<LocationChangeEvent> {
    return this.popstateEventsBehaviorSubject.asObservable();
  }

  constructor() {

    // reveicing the number of context parts in an url in the current configuration
    this.urlContextPartsSub = this.configurationService.unifiedConfig$.pipe(filter(_ => !!_)).subscribe(config => {
      this.urlContextParts = config.context?.urlParameters || [];
    });

    // get every page change
    this.routerEventsSub = this.router.events.pipe(filter(event => (event instanceof NavigationEnd))).subscribe(event => {
      this.getNavigationItem(true).pipe(take(1)).subscribe(item => {
        if (this.historyItemReplaceMode || this.history.value === null) {
          this.history.replace(item);
        } else {
          this.history.next(item);
        }
        this.historyItemReplaceMode = false;
      });
    });

    this.onpopstateKindOfSubscription = this.location.onPopState(e => {
      this.history.undo();
      this.historyItemReplaceMode = true;
      this.popstateEventsBehaviorSubject.next(e);
    });
  }

  ngOnDestroy(): void {
    this.urlContextPartsSub.unsubscribe();
    this.routerEventsSub.unsubscribe();
    this.onpopstateKindOfSubscription?.();
  }

  /**
   * @param params - Params object
   * @param method - the method on how the give params are included into the current url:
   *
   * 'merge': adds the given parameter to the current one, overwrites given value if the key already exists;
   *
   * 'replace': deletes the current parameter and replace them with the given completely
   *
   * 'extend': adds the given parameter to the current one, keeps the given value if the key already exists;
   *
   * NOTE: Calling this method does not add a navigation node to Angular's Navigation history
   */
  changeParamsInUrl(params: UtilNavigationItem['queryParams'], method: 'merge' | 'replace' | 'extend' = 'merge') {

    const curUrl = this.windowRef.location;
    let currentParams = this.getParamsObject(curUrl?.search || '');

    switch(method) {
      case 'merge': {
        Object.keys(params).forEach(key => currentParams[key] = params[key]);
      } break;
      case 'extend': {
        Object.keys(params).forEach(key => {
          if (typeof currentParams[key] === 'undefined') {
            currentParams[key] = params[key];
          }
        });
      } break;
      case 'replace': currentParams = params;
    }

    this.paramChange(currentParams);

  }

  private paramChangeHelpObject = {
    setTimeoutId: null,
    scrollToAnchorTmp: null,
    scrollToPositionTmp: null,
    revertScroller: () => {
      this.viewportScroller.scrollToAnchor = this.paramChangeHelpObject.scrollToAnchorTmp.bind(this.viewportScroller);
      this.viewportScroller.scrollToPosition = this.paramChangeHelpObject.scrollToPositionTmp.bind(this.viewportScroller);
    },
    startTimeoutForRevert: () => {
      this.paramChangeHelpObject.setTimeoutId = this.windowRef.nativeWindow.setTimeout(
        () => (this.paramChangeHelpObject.revertScroller()),
        1000
      );
    },
    clearTimeoutIfActive: () => {
      if (this.paramChangeHelpObject.setTimeoutId !== null) {
        this.windowRef.nativeWindow.clearTimeout(this.paramChangeHelpObject.setTimeoutId);
        this.paramChangeHelpObject.setTimeoutId = null;
        this.paramChangeHelpObject.revertScroller();
      }
    },
    overrideScroller: () => {
      this.paramChangeHelpObject.scrollToAnchorTmp = this.viewportScroller.scrollToAnchor;
      this.paramChangeHelpObject.scrollToPositionTmp = this.viewportScroller.scrollToPosition;
      this.viewportScroller.scrollToAnchor = function(anchor: string) {
        // console.log('overridden ViewportScroller.scrollToAnchor(' + anchor + ')');
      };
      this.viewportScroller.scrollToPosition = function(pos: [number, number]) {
        // console.log('overridden ViewportScroller.scrollToPosition(' + pos?.[0] + ', ' + pos?.[1] + ')');
      };
    },
  };

  private paramChange(params: UtilNavigationItem['queryParams']) {

    this.paramChangeHelpObject.clearTimeoutIfActive();

    this.paramChangeHelpObject.overrideScroller();

    this.router.navigate([], {
      queryParams: params
    })
    .catch(() => (this.paramChangeHelpObject.startTimeoutForRevert()))
    .then(() => (this.paramChangeHelpObject.startTimeoutForRevert()));

  }

  getNavigationPageData(): Observable<UtilNavigationPageData> {
    return combineLatest([
      this.cmsService.getCurrentPage(),
      this.history$
    ]).pipe(
      filter(([page]) => !!page),
      map(([page, item]) => {

        let rule = this.getNavigationRule(page.pageId) || UtilNavigationRule.None;
        let targetItem = this.getTargetNavigationItemAccordingToRule(rule);

        // there should always be a target unless the rule is None
        if (!targetItem && rule !== UtilNavigationRule.None) {
          rule = UtilNavigationRule.OnlyHomepage;
          targetItem = {
            pageUrl: '/',
            type: 'pageChange'
          };
        }

        return {
          rule,
          targetItem
        };

      }
      ));
  }

  private getTargetNavigationItemAccordingToRule(rule: UtilNavigationRule): UtilNavigationItem {

    if (rule === UtilNavigationRule.OnlyHomepage) {
      return {
        pageUrl: '/',
        type: 'pageChange'
      };
    }

    if (rule === UtilNavigationRule.ToCart) {
      return {
        pageUrl: '/cart',
        type: 'pageChange'

      };
    }

    if (rule === UtilNavigationRule.ToOrderHistory) {
      return {
        pageUrl: '/my-account/orders',
        type: 'pageChange'
      };
    }

    if (rule === UtilNavigationRule.HistoryBack) {
      return this.history.getLastValue(1);
    }

    if (rule === UtilNavigationRule.PageBack) {

      const currentType = this.history.value.type;
      let numUndos = 0;

      // if the current type is page change then 1 undo changes back to the next page
      if (currentType === 'pageChange') {
        numUndos = 1;
      } else {
        // if current type is query change then go back until the page has changed (which will be the change to the current site)
        // then 1 undo extra will lead to previous page from the current
        numUndos = this.history.getHowManyUndoUntil(item => item.type === 'pageChange', 1) + 1;
      }

      return this.history.getLastValue(numUndos);
    }
  }

  addNavigationRule(pageId: string, navigationRule: UtilNavigationRule) {
    this.pageId_navigationRuleMap.set(pageId, navigationRule);
  }

  addNavigationRuleMap(pageIdToNavigationRuleMap: Map<string, UtilNavigationRule>) {
    Array.from(pageIdToNavigationRuleMap.entries()).forEach(([pageId, rule]) => {
      this.addNavigationRule(pageId, rule);
    });
  }

  private getNavigationRule(pageId: string): UtilNavigationRule {
    return this.pageId_navigationRuleMap.get(pageId);
  }

  back(rule?: UtilNavigationRule) {

    let pageId: string;

    if (!rule) {
      this.cmsService.getCurrentPage().pipe(take(1)).subscribe(page => pageId = page.pageId);
      rule = this.getNavigationRule(pageId) || UtilNavigationRule.None;
    }

    if (rule === UtilNavigationRule.None) {
      return void 0;
    }

    const targetItem = this.getTargetNavigationItemAccordingToRule(rule);


    /**
     * only for these rules we should undo
     */
    if (rule === UtilNavigationRule.HistoryBack || rule === UtilNavigationRule.PageBack) {
      const undoUntilTarget = this.history.getHowManyUndoUntil(item => {
        return item === targetItem;
      });
      return this.history.undoMany(undoUntilTarget);
    }

    this.historyItemReplaceMode = true;

  }

  readUrlQueryParams(): UtilNavigationItem['queryParams'] {
    const searchStringRaw = this.windowRef.location.search.startsWith('?') ? this.windowRef.location.search.slice(1) : this.windowRef.location.search;
    return this.getParamsObject(searchStringRaw || '');
  }

  private getNavigationItem(fromNavigationEndContext: boolean, type?: UtilNavigationItem['type']): Observable<UtilNavigationItem> {

    // timer(0) is used to get out of the runtime cycle of page changes of the router.event and the store for the page data that cmsService.getCurrentPage() uses is
    // emptied so that cmsService.getCurrentPage() returns current data

    const obs = fromNavigationEndContext ? timer(0) : of(0);

    return obs.pipe(
      switchMap(() => (this.cmsService.getCurrentPage())),
      take(1),
      map(pageData => {

        // get url
        const href = this.windowRef.location.href;

        // get contextPieces
        const path = this.windowRef.location.pathname;
        const pathParts = path.split('/').filter(part => !!part);

        const contextPieces: UtilNavigationItem['contextPieces'] = [];

        for (let i = 0; i < this.urlContextParts.length; i++) {
          contextPieces.push({key: this.urlContextParts[i], value: pathParts[i]});
        }

        // pageUrl from path parts
        const pageUrl = '/' + pathParts.slice(this.urlContextParts.length).join('/');

        // get queryParams
        const queryParams = this.readUrlQueryParams();

        // if no type is specified than we try to find out
        // 1. if there is no previous values in the history => it is 'pageChange'
        // 2. if there is then if it has the same pageId then the current one -> 'queryChange' else 'pageChange'
        if (!type) {
          if (!this.history.value) {
            type = 'pageChange';
          } else {
            type = (this.history.value.pageId === pageData?.pageId ? 'queryChange' : 'pageChange');
          }
        }

        return {
          type,
          url: href,
          pageUrl,
          pageId: pageData.pageId,
          contextPieces,
          queryParams
        } as UtilNavigationItem;

      })
    );

  }

  private getParamsObject(paramStringRaw: string): UtilNavigationItem['queryParams'] {
    const queryParams: UtilNavigationItem['queryParams'] = {};

    if (paramStringRaw.startsWith('?')) {
      paramStringRaw = paramStringRaw.slice(1);
    }

    if (paramStringRaw) {
      const paramStrings = paramStringRaw.split('&');
      paramStrings.forEach(paramStr => {
        let [key, value] = paramStr.split('=');
        // extra solar cleaning
        const codec = new HttpUrlEncodingCodec();
        value = codec.decodeValue(value);
        queryParams[key] = value;
      });
    }

    return queryParams;
  }

}
